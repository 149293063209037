.input_copy_wrapper{
    padding: 50px;
    margin : 0 auto;
  }
  .input_copy {
      padding: 15px 25px;
      background: #eee;
      border: 2px solid #aaa;
      color: #aaa;
      font-size: .8em;
  }
  
  .input_copy .icon {
      display: block;
      max-width: 25px;
      cursor: pointer;
      float: right;
  }
  
  .input_copy .icon img{
    max-width: 25px;
  }
  .input_copy .txt {
      width: 80%;
      display: inline-block;
      overflow: hidden;
  }
  
  
  /* click animation */
  
  .flashBG {
      animation-name: flash;
      animation-timing-function: ease-out;
      animation-duration: 1s;
  }
  
  @keyframes flash {
      0% {
          background: #28a745;
      }
      100% {
          background: transparent;
      }
  }
  
  .input_copy_wrapper .btn_whtsapp_share{
      background-image: url(../../../../public/assets/img/whatsapp_share.png)
  }
  .modal-body .whtsapp-share{
    width: inherit;
    height: 75px;
  }  
  .modal-body .mail-share{
    margin-top: 5px;
  }
 .modal-body .share-container{
    padding: 0px 50px;
  }
  .modal-body .mail-share i{
    margin-right: 5px;
  }

  .grid-view-content .row.align-items-start{
    background-color: #343a40;
    color: #fff;
    border-radius: 5%;
    margin: 1px 0px 5px 1px;
  }
  .grid-view-content .row.align-items-center{
    background-color: #ccc;
    border-radius: 5%;
    margin: 1px 0px 5px 1px;
  } 
  .grid-view-content .row div{
    border: 1px solid #fff;
  }
  i.fa.fa-sync-alt:hover {
    cursor: pointer;    
}