@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
:root {
    --primary: #000000;
    --secondary: #c7c8c9;
    --light: #f3f4f5;
    --dark: #000000;
    --border: #ffffff;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
* {
	text-decoration: none !important;
}
body {
	min-height: 100vh;
	font-family: 'Poppins', sans-serif !important;
	font-size: 16px !important;
	background-color: #fff;
}

.table{
	border:1px solid #2889d1;
}
.table th, .table td{
	font-weight: 300
}
.table td{
	font-size: 14px;
}
.paid{
    display: inline-block;
    width: 90px;
    padding: 3px 0;
    background: #1e9d39;
    color: #fff;
    border-radius: 20px;
    font-size: 13px;
}
.notpaid{
	display: inline-block;
	width: 90px;
    padding: 3px 0;
	background: #e71111;
	color: #fff;
	border-radius: 20px;
    font-size: 13px;
}
.t-head-bg {
    background-color: #2889d1;
}
/*Table Striped*/
.tbody_strip tr:nth-child(even) {
	background-color: #e1f0fb;
}
.page-item.active .page-link{
	background-color: #2889d1;
    border-color: #2889d1;
}
.page-link, .page-item.disabled .page-link{
	border-color: #2889d1;
}
.payment-header{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 0;
}
.payment-content{
	width: 100%;
	background-color: #f6f6f6;
	padding: 35px 0;
	min-height: calc(100vh - 162px);
}
.content-box{
	width: 830px;
	max-width: 100%;
	margin: 0 auto;
}
.backLink{
	text-decoration: underline !important;
	color: #000;
	font-weight: 500;
	font-size: 12px;
}
.white-box{
	background: #fff;
	border: 1px solid #ccc;
	width: 100%;
	padding: 25px;
	margin-top: 20px;
}
.white-box h3{
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 25px;
}
.white-box h4{
	font-size: 16px;
}
.white-box h5{
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 25px;
}
.shping_ul{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	list-style-type: none;
	padding-left: 0;
	margin-bottom: 0;
	font-size: 12px;
	max-width: 400px;
}
.shping_ul li{
	position: relative;
}
.shping_ul li:after{
	width: 140%;
    height: 2px;
    background: #ccc;
    position: absolute;
    top: 28px;
    left: 70%;
    content: '';
}
.shping_ul li:last-child:after{
	display: none;
}
.order-row{
	width: 100%;
	display: flex;
}
.order_itm{
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	width: calc(100% - 80px);
	text-align: left;
}
.order_itm .thumb{
	width: 40px;
	height: 38px;
	background: #dbdbdb;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}
.item_name{
	width: calc(100% - 50px);
	display: flex;
	flex-wrap: wrap;
}
.item_name span{
	width: 100%;
	display: inline-block;
}
.order_price{
	width: 80px;
	margin-left: 15px;
	font-size: 14px;
	text-align: right;
}
.payment-gateway{
	padding-left: 0;
	list-style-type: none;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 0;
}
.payment-gateway li{
	margin-bottom: 15px;
	height: 40px;
	margin-right: 10px;
}
.payment-gateway li .payment-outer{
	display: inline-block;
	position: relative;
}
.payment-gateway li label{
	border: 1px solid #ccc;
	height: 40px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.payment-gateway li input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.payment-gateway li input:checked ~ label{
	border: 1px solid #097b3f;
}
.order-row.shipping{
	margin-top: 10px;
}
.order-row.taxes{
	margin-bottom: 10px;
}
.order-row.total{
	font-weight: bold;
	font-size: 18px;
	margin-bottom: 10px;
	margin-top: 5px;
}
.order-row.continue a{
	width: 100%;
	background-color: #008337;
	color: #fff;
	font-weight: bold;
	font-size: 14px;
	height: 35px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.order-row.cancel{
	padding-top: 15px;
	justify-content: center;
}
.order-row.cancel a{
	display: inline-block;
	background-color: transparent;
	color: #4b4a48;
	font-weight: bold;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: underline !important;
}
@media (min-width: 575px){
	.collapse-amount, .mobile_show{
		display: none;
	}
}
@media (min-width: 850px){
	.container.payment_page{
		max-width: 850px;
	}
}
@media only screen and (max-width: 850px) {
	.content-box{
		padding: 0 15px;
	}
	.shping_ul li:after{
		width: 110%;
	}
	
}
@media only screen and (max-width: 768px) {
	.backLink{
		margin-bottom: 15px;
		display: inline-block;
	}
}
@media only screen and (max-width: 575px) {
	.mobile_hide{
		display: none;
	}
	.amount-btn{
		width: 100%;
		background: #fff;
		border: 0;
		color: #000;
		text-align: left;
		padding: 10px 15px;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
	}
	.amount-btn img{
		width: 15px;
		position: relative;
		top: 8px;
		transform: scale(-1);
	}
	.amount-btn.collapsed img{
		transform: scale(1);
	}
	#amount_content{
		background: #fff;
		padding: 15px;
		border-top: 1px solid #ccc;
	}
	.shping_ul{
		display: none;
	}
	.white-box{
		margin-top: 5px;
	}
	.payment-footer{
		flex-wrap: wrap;
	}
	.ftr_logo{
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.ftr_links{
		width: 100%;
	}
	.payment-footer ul{
		padding-left: 0;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 20px;
	}
	.payment-footer ul li{
		font-size: 12px;
		margin-bottom: 10px;
	}
	.payment-footer ul li:before{
		display: none;
	}
	.payment-header img{
		max-width: 40%;
	}
	.order-row.continue.mobile_show a{
		border-radius: 0;
	}
}