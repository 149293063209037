.div-paginate {
  display: flex;
  justify-content: center;
}
      
.div-paginate ul {
  margin-bottom: 2rem;
display: flex;
flex-direction: row;
justify-content: space-between;
list-style-type: none;
padding: 0 5rem;
}
      
.div-paginate li {
  margin: 0 1px;
}
.div-paginate li a {
  border-radius: 7px;
  padding: 0.1rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
}
.div-paginate li.previous a,
  .div-paginate li.next a,
  .div-paginate li.break a {
    border-color: transparent;
  }
  .div-paginate li.selected a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }

  .div-paginate li:hover a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }

  .div-paginate li.disabled a {
    color: grey;
  }
  .div-paginate li.disable,
  .div-paginate li.disabled a {
    cursor: default;
  }